import React, { useState } from "react";
import "./App.css";
import PalmTreeIcon from "./palm-tree-icon.svg"; // Import the SVG
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Verifier from "./verifier/Verifier";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          {/* Logo or Company Name on the Left */}
          <div className="navbar-logo">
            cravity
            <img src={PalmTreeIcon} alt="Palm Tree Icon" className="navbar-icon" />
          </div>

          {/* Hamburger Icon for Mobile */}
          <div className="hamburger-icon" onClick={toggleMenu}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>

          {/* Background Overlay */}
          <div className={`overlay ${menuOpen ? "active" : ""}`} onClick={closeMenu}></div>

          {/* Sliding Navbar Links with Close Button */}
          <div className={`navbar-links ${menuOpen ? "open" : ""}`}>
            <button className="close-button" onClick={closeMenu}>✕</button>
            <Link to="/" onClick={closeMenu}>Home</Link>
            <Link to="/about" onClick={closeMenu}>About</Link>
            <Link to="/services" onClick={closeMenu}>Services</Link>
            <Link to="/contact" onClick={closeMenu}>Contact</Link>
          </div>
        </nav>

        {/* Define Routes */}
        <Routes>
          <Route path="/" element={
            <div id="home">
              <h1>Welcome to Cravity</h1>
              <p>Your one-stop solution for all services.</p>
            </div>
          } />
          <Route path="/about" element={
            <div id="about">
              <h1>About Us</h1>
              <p>Learn more about Cravity and our mission.</p>
            </div>
          } />
          <Route path="/services" element={
            <div id="services">
              <h1>Our Services</h1>
              <p>Explore the wide range of services we offer.</p>
            </div>
          } />
          <Route path="/contact" element={
            <div id="contact">
              <h1>Contact Us</h1>
              <p>Get in touch with us for more information.</p>
            </div>
          } />
          <Route path="/verify" element={<Verifier />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
